const home = () => {
    // invertImage("#slideshow1 img", 1200)
    // invertImage("#slideshow2 img", 1400)
    // invertImage("#slideshow3 img", 1000)
};

export default home;

// function invertImage(selector, interval) {
//     let invert = 1;

//     setInterval(() => {
//         $(selector).css({filter : `invert(${invert})`})
//         invert = invert === 1 ? 0 : 1;
//     }, interval);
// }
